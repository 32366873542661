import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignInSuccessGenerator, userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  APPROVE_DECLINE_SALARY_ADVANCE,
  BULK_APPROVE_DECLINE_SALARY_ADVANCES, FETCH_SALARY_ADVANCE_LOANS,
  DISBURSED_SALARY_ADVANCE, disbursedSalaryAdvanceGenerator,
  disbursedSalaryAdvanceSuccessGenerator,
  FETCH_FINANCED_SALARY_ADVANCES,
  fetchFinancedSalaryAdvanceGenerator,
  fetchFinancedSalaryAdvanceSuccessGenerator, MARK_AS_PAID_SALARY_ADVANCE, fetchFinancedSalaryAdvanceLoansGenerator, fetchFinancedSalaryAdvanceLoansSuccessGenerator,
  PAUSE_SALARY_ADVANCE_LOANS, RECORD_LUMPSUM_SALARY_ADVANCE_LOANS, TOPUP_SALARY_ADVANCE_LOANS, UNPAUSE_SALARY_ADVANCE_LOANS, UPDATE_SALARY_ADVANCE_LOANS
} from "../../actions/admin/CompaniesFinancedSalaryAdvanceActions";
import {
  fetchAdminSalaryAdvanceGenerator,
  PAY_ADMIN_SALARY_ADVANCE
} from "../../actions/admin/SalaryAdvancesSettingsAction";
import {
  AUTH_DEFAULT_APP,
  AUTH_HEAD_OFFICE_ID_KEY, AUTH_ROLE_KEY,
  AUTH_SWITCHED_APP,
  AUTH_SWITCHED_COMPANY_EMAIL_KEY,
  AUTH_SWITCHED_COMPANY_ID_KEY, AUTH_USER_KEY, SUBSCRIPTION_KEY, SYSTEM_ADMIN
} from "../../../constants/SessionKeys";
import { push } from "connected-react-router";

const fetchDisbursedSalaryAdvancesRequest = async (requestParameters) =>
  await axios.get(API_ENDPOINT + '/disbursed/by-workpay/salary-advances', { params: requestParameters })
    .then(response => response)
    .catch(error => error.response);

const MarkASPaidSalaryAdvancesRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/mark-paid/by-workpay/salary-advances', requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchFinancedSalaryAdvancesRequest = async (requestParameters) =>
  await axios.get(API_ENDPOINT + '/financed/by-workpay/salary-advances', { params: requestParameters })
    .then(response => response)
    .catch(error => error.response);

const approveDeclineSalaryAdvancesRequest = async (requestParameters) =>
  await axios.put(API_ENDPOINT + '/financed/by-workpay/salary-advances/' + requestParameters.id, requestParameters)
    .then(response => response)
    .catch(error => error.response);

const paySalaryAdvancesRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/pay/financed/by-workpay/salary-advances', requestParameters)
    .then(response => response)
    .catch(error => error.response);

/** Salary advance loans */
const fetchFinancedSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.get(API_ENDPOINT + '/salary-advances-loans', { params: requestParameters })
    .then(response => response)
    .catch(error => error.response);
const updateSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.put(API_ENDPOINT + '/salary-advances-loans/' + requestParameters.id, requestParameters)
    .then(response => response)
    .catch(error => error.response);
const topupSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/salary-advances-loans/top-up', requestParameters)
    .then(response => response)
    .catch(error => error.response);
const pauseSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/salary-advances-loans/pause', requestParameters)
    .then(response => response)
    .catch(error => error.response);
const unpauseSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/salary-advances-loans/unpause', requestParameters)
    .then(response => response)
    .catch(error => error.response);
const recordLumpsumSalaryAdvanceLoansRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/salary-advances-loans/lump-sum-payment', requestParameters)
    .then(response => response)
    .catch(error => error.response);

const bulkApproveDeclineSalaryAdvancesRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT + '/bulk/approval', requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchDisbursedSalaryAdvance({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token() };
    const response = yield call(fetchDisbursedSalaryAdvancesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(disbursedSalaryAdvanceSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* MarkASPaidSalaryAdvances({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token() };
    const response = yield call(MarkASPaidSalaryAdvancesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(disbursedSalaryAdvanceGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* bulkApproveDeclineSalaryAdvances({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(bulkApproveDeclineSalaryAdvancesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let rolesById = response.data.data;
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* bulkApproveDeclineSalaryAdvancesDefault() {
  yield takeLatest(BULK_APPROVE_DECLINE_SALARY_ADVANCES, bulkApproveDeclineSalaryAdvances)
}

function* paySalaryAdvances({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(paySalaryAdvancesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let responseData = response.data.data;
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, responseData?.company?.id);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, responseData?.company?.id);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_EMAIL_KEY, responseData?.company?.email);
        Helpers.saveItem(AUTH_SWITCHED_APP, 'payouts');
        let request = { id: responseData?.company?.id };
        const cacheExternalType = responseData?.payment_cache?.external_type;
        yield put(push('/payouts/bulk-payouts?type=' + cacheExternalType));
        this.props.switchCompanyGenerator(request);
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* approveDeclineSalaryAdvances({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(approveDeclineSalaryAdvancesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let rolesById = response.data.data;
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchFinancedSalaryAdvance({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token() };
    const response = yield call(fetchFinancedSalaryAdvancesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchFinancedSalaryAdvanceSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

/** Salary advance loans */
function* fetchFinancedSalaryAdvanceLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token() };
    const response = yield call(fetchFinancedSalaryAdvanceLoansRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let loans = response.data.data;
        yield put(fetchFinancedSalaryAdvanceLoansSuccessGenerator(loans));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateSalaryAdvanceLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(updateSalaryAdvanceLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceLoansGenerator({ recordsPerPage: 15 }));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* topupSalaryAdvancesLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(topupSalaryAdvanceLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceLoansGenerator({ recordsPerPage: 15 }));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* pauseSalaryAdvanceLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(pauseSalaryAdvanceLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceLoansGenerator({ recordsPerPage: 15 }));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* unpauseSalaryAdvanceLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(unpauseSalaryAdvanceLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceLoansGenerator({ recordsPerPage: 15 }));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* recordLumpsumSalaryAdvanceLoans({ type, payload }) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = { ...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID() };
    const response = yield call(recordLumpsumSalaryAdvanceLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchFinancedSalaryAdvanceLoansGenerator({ recordsPerPage: 15 }));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText, 'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error, 'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* paySalaryAdvancesDefault() {
  yield takeLatest(PAY_ADMIN_SALARY_ADVANCE, paySalaryAdvances)
}
export function* fetchFinancedSalaryAdvanceDefault() {
  yield takeLatest(FETCH_FINANCED_SALARY_ADVANCES, fetchFinancedSalaryAdvance)
}
export function* approveDeclineSalaryAdvancesDefault() {
  yield takeLatest(APPROVE_DECLINE_SALARY_ADVANCE, approveDeclineSalaryAdvances)
}

/** Salary advance loans */
export function* fetchFinancedSalaryAdvanceLoansDefault() {
  yield takeLatest(FETCH_SALARY_ADVANCE_LOANS, fetchFinancedSalaryAdvanceLoans)
}
export function* updateSalaryAdvanceLoansDefault() {
  yield takeLatest(UPDATE_SALARY_ADVANCE_LOANS, updateSalaryAdvanceLoans)
}
export function* topupSalaryAdvanceLoansDefault() {
  yield takeLatest(TOPUP_SALARY_ADVANCE_LOANS, topupSalaryAdvancesLoans)
}
export function* pauseSalaryAdvanceLoansDefault() {
  yield takeLatest(PAUSE_SALARY_ADVANCE_LOANS, pauseSalaryAdvanceLoans)
}
export function* unpauseSalaryAdvanceLoansDefault() {
  yield takeLatest(UNPAUSE_SALARY_ADVANCE_LOANS, unpauseSalaryAdvanceLoans)
}
export function* recordLumpsumSalaryAdvanceLoansDefault() {
  yield takeLatest(RECORD_LUMPSUM_SALARY_ADVANCE_LOANS, recordLumpsumSalaryAdvanceLoans)
}
export function* fetchDisbursedSalaryAdvanceDefault() {
  yield takeLatest(DISBURSED_SALARY_ADVANCE, fetchDisbursedSalaryAdvance)
}

export function* MarkASPaidSalaryAdvancesDefault() {
  yield takeLatest(MARK_AS_PAID_SALARY_ADVANCE, MarkASPaidSalaryAdvances)
}


export default function* rootSaga() {
  yield all([
    fork(fetchFinancedSalaryAdvanceDefault),
    fork(approveDeclineSalaryAdvancesDefault),
    fork(paySalaryAdvancesDefault),

    fork(fetchFinancedSalaryAdvanceLoansDefault),
    fork(updateSalaryAdvanceLoansDefault),
    fork(topupSalaryAdvanceLoansDefault),
    fork(pauseSalaryAdvanceLoansDefault),
    fork(unpauseSalaryAdvanceLoansDefault),
    fork(recordLumpsumSalaryAdvanceLoansDefault),
    fork(bulkApproveDeclineSalaryAdvancesDefault),
    fork(fetchDisbursedSalaryAdvanceDefault),
    fork(MarkASPaidSalaryAdvancesDefault)
  ]);
}
